var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{staticClass:"width-dialog-sm-and-down dialog-box",attrs:{"width":"430"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',{staticClass:"dialog-container width-dialog-sm-and-down"},[_c('div',{staticClass:"dialog-header"},[_c('h2',[_vm._v("Update ATM")])]),_c('div',{staticClass:"dialog-body"},[_c('div',{staticClass:"transparent sent-content"},[_c('el-form',{ref:"form",attrs:{"model":_vm.formData,"rules":_vm.rules}},[_c('div',[_c('div',{staticClass:"form-item"},[_c('span',[_vm._v("Date ")]),_c('el-form-item',[_c('v-date-picker',{attrs:{"disabled":""},model:{value:(_vm.formData.date),callback:function ($$v) {_vm.$set(_vm.formData, "date", $$v)},expression:"formData.date"}})],1)],1)]),_c('div',[_c('div',{staticClass:"form-item"},[_c('span',[_vm._v("Handler Name")]),_c('el-form-item',[_c('v-input',{model:{value:(_vm.formData.handle_name),callback:function ($$v) {_vm.$set(_vm.formData, "handle_name", $$v)},expression:"formData.handle_name"}})],1)],1),_c('div',{staticClass:"form-item "},[_c('span',[_vm._v("Bank No.")]),_c('el-form-item',[_c('v-select',{attrs:{"list":_vm.globalOptions.banks},model:{value:(_vm.formData.bank_no_id),callback:function ($$v) {_vm.$set(_vm.formData, "bank_no_id", $$v)},expression:"formData.bank_no_id"}})],1)],1),_c('div',{staticClass:"form-item "},[_c('span',[_vm._v("Account No.")]),_c('el-form-item',[_c('v-input',{model:{value:(_vm.formData.account_no),callback:function ($$v) {_vm.$set(_vm.formData, "account_no", $$v)},expression:"formData.account_no"}})],1)],1),_c('div',{staticClass:"form-item "},[_c('span',[_vm._v("Receipt")]),_c('el-form-item',{attrs:{"prop":"policy_document_file_id"}},[_c('file-container',{attrs:{"edit":true,"fileData":{
                    url: _vm.formData.receipt_file_url,
                    client_name: _vm.formData.receipt_client_name
                  },"otherData":{
                    idKey: 'receipt_file_id',
                    urlKey: 'receipt_file_url',
                    client_name:'receipt_client_name'
                  }},on:{"delete":_vm.handlerDeleteFile,"uploadSuccess":_vm.handlerUploadSuccess}})],1)],1)])])],1)]),_c('div',{staticClass:"dialog-footer",attrs:{"align":"right"}},[_c('div',{staticClass:"btn-container"},[_c('v-button',{staticClass:"button-theme-block",attrs:{"size":"small"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Cancel ")]),_c('v-button',{staticClass:"button-theme",attrs:{"size":"small","type":"primary"},on:{"click":_vm.handlerSave}},[_vm._v("Save ")])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }