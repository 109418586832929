<template>
  <table class="table-list table-list--policy_premium">
    <tr class="th">
      <th>Document</th>
      <th >Created Date</th>
      <th >Send to Client</th>
    </tr>
    <tr>
      <td>
        <span class="table-span">
          <span>{{isEndorsement?'Endorsement':''}} Cover Note</span>
          <span class="fs-400">{{form.covernote_no}}</span>
        </span>
        <span
          v-if="covernote_document.client_name"
          class="theme-second-blue hand"
          @click="openFile(covernote_document)"
        >{{covernote_document.client_name}}</span>
        <span
          v-else
          class="none-client-name">Please Upload Cover Note</span>
      </td>
      <td class="text-top">
        <template  v-if="covernote_document_upload.length>0">
          <span
            v-for="(item,index) in covernote_document_upload"
            :key="'covernote_document'+index"
            class="block block-span">
            <span>{{item.date}}</span>
            <span>{{item.created_by}}</span>
          </span>
        </template>
        <template v-else>
          <span>-</span>
        </template>
      </td>
      <td >
        <span
          v-for="(item,index) in covernote_document_send"
          :key="'covernote_document'+index"
          class="block block-span">
          <span>{{item.date}}</span>
          <span>{{item.type}}</span>
        </span>
        <span class="block">
          <v-button
            class="button-theme"
            @click="handleUpoad(2,covernote_document)">{{covernote_document.client_name?'Send':'Upload'}}</v-button>
        </span>
      </td>
    </tr>
    <tr>
      <td>
        <span class="table-span">
          <span>{{isEndorsement?'Endorsement ':''}}Policy</span>
          <span class="fs-400">{{form.policy_no}}</span>
        </span>
        <span
          v-if="policy_document.client_name"
          class="theme-second-blue hand"
          @click="openFile(policy_document)"
        >{{policy_document.client_name}}</span>
        <span
          v-else
          class="none-client-name">Please Upload Policy</span>
      </td>
      <td class="text-top">
        <template v-if="policy_document_upload.length>0">
          <span
            v-for="(item,index) in policy_document_upload"
            :key="'policy_document'+index"
            class="block block-span">
            <span>{{item.date}}</span>
            <span>{{item.created_by}}</span>
          </span>
        </template>
        <span v-else>-</span>
      </td>
      <td >
        <span
          v-for="(item,index) in policy_document_send"
          :key="'policy_document'+index"
          class="block block-span">
          <span>{{item.date}}</span>
          <span>{{item.type}}</span>
        </span>
        <span class="block">
          <v-button
            class="button-theme"
            @click="handleUpoad(1,policy_document)">{{policy_document.client_name?'Send':'Upload'}}</v-button>
        </span>
      </td>
    </tr>
  </table>
</template>

<script>
import MixinFormat from '@/mixins/format'
import MixinOption from '@/mixins/options'
export default {
  name: 'documentInfo',
  mixins: [MixinFormat,MixinOption],
  props:{
    form:{
      type:Object,
      default:()=>({})
    }
  },
  computed:{
    isCompany(){
      return this.form.client_type==2
    },
    productId(){
      return this.form.product_id
    },
    isEndorsement(){
      return this.form.policy_type==203
    },
    covernote_document(){
      let {covernote_document} = this.form||{}
      return covernote_document||{}
    },
    policy_document(){
      return  this.form.policy_document||{}
    },
    policy_document_upload(){
      let {upload_history=[]} = this.policy_document||{}
      return upload_history||[]
    },
    policy_document_send(){
      let {send_history=[]} = this.policy_document||{}
      return send_history||[]
    },
    covernote_document_upload(){
      let {upload_history=[]} = this.covernote_document||{}
      return upload_history||[]
    },
    covernote_document_send(){
      let {send_history=[]} = this.covernote_document||{}
      return send_history||[]
    },
    documentList(){
      let list = {
        10:this.documentOptions,
        11:this.contractorDocumentOptions,
        12:this.travelDocumentOptions,
        13:this.travelDocumentOptions,
        14:this.employeeDocumentOptions,
        15:this.businessDocumentOptions,
        16:this.travelDocumentOptions,
        17:this.conEmployeeDocumentOptions,
        18:this.travelDocumentOptions
      }
      let {class_id,client_type} = this.form||{}
      let result = []
      this.globalOptions.document_type.some(item=>{

        if (item.class_id == class_id) {
          item.data.some(client=>{
            if(client.client_type==client_type)
              result = client.items
          })
        }
      })
      return result
      return list[this.productId]
    },
  },
  methods:{
    openFile(data) {
      const { url } = data
      if(url) {
        window.open(url, '_blank')
      }
    },
    handleUpoad(type,doc){
      let isUpload =JSON.stringify(doc)!='{}'
      this.$emit('upload',type,isUpload)
    }
  }
}
</script>

<style scoped lang="scss">
.theme-second-blue{
  margin-bottom: 8px;
}
</style>
