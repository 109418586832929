<template>
  <div>
    <div class="right ">
      <div class="card">
        <div>
          <div class="flex">
            <span class="label is-bold">Documents Status</span>
          </div>
          <div>
            <div
              class="table"
            >
              <div
                v-for="(item,index) in document_status.items"
                :key="index"
                class="tr">
                <span>{{item.name}}</span>
                <div
                  v-if="document_status.step!==index&&index!=3&&index!==4"
                  class="flex flex-sb">
                  <span
                  >{{showHkDate(item.date)||'-'}}</span>
                  <span
                    v-if="item.date"
                    class="link edit hand"
                    @click="handleClick(index)">edit</span>
                </div>
                <div
                  v-else-if="document_status.step!==index&&(index==3||index==4)&&item.date"
                  class="flex flex-sb">
                  <span
                  >{{showHkDate(item.date)||'-'}}</span>
                  <span
                    v-if="item.date"
                    class="link edit hand"
                    @click="handleClick(index)">edit</span>
                </div>
                <div
                  v-else-if="(document_status.step!==3&&document_status.step!==4)&&(index==3||index==4)&&!item.date"
                  class="flex flex-sb">
                  <span
                  >{{showHkDate(item.date)||'-'}}</span>
                  <span
                    v-if="item.date"
                    class="link edit hand"
                    @click="handleClick(index)">edit</span>
                </div>
                <div
                  v-if="(document_status.step==3||document_status.step==4)&&!item.date"
                  class="flex ">
                  <v-button
                    class="button-theme"
                    @click="handleClick(index)">Update</v-button>
                </div>
                <div
                  v-else-if="document_status.step==index&&!item.date"
                  class="flex ">
                  <v-button
                    class="button-theme"
                    @click="handleClick(document_status.step)">Update</v-button>
                </div>
              </div>

            </div>

          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import MixinOptions from '@/mixins/options'

export default {
  name: 'document',
  mixins: [MixinOptions],
  props:{
    isRenew:{
      type:Boolean,
      default:false
    },
    isAble:{
      type:Boolean,
      default:true
    },
    form: {
      type: Object,
      default: () => ({})
    },

  },
  computed:{
    ...mapState('quotation', ['quoteInformationForm']),
    document_status(){
      return this.form.document_status||{}
    }
  },
  methods:{
    ...mapActions('quotation', ['setQuoteInformationForm']),
    handleClick(step){
      // console.log('handlerUpdate',step)
      // if(step==0)
      //   this.$emit('edit',this.form.policy_type)
      // else if(step==1)
      //   this.$emit('handlerUpdate',2)

      this.$emit('step',step)
      // if(step==0)
      //   this.handlerEdit(this.formData.policy_type)
      // else if(step==1)
      //   this.updateHirePurchase(2)
      // else if(step==2)
      //   this.$refs.policyReceived.showReferDialog()
      // else if(step==3)
      //   this.updateHirePurchase(1)
    },
    updateHirePurchase(type){
      this.type = type
      this.$refs.hirePurchaseDialog.showReferDialog()
    },
    hanlderCancel(){
      this.$router.push('/quotation/cancel')
    },
    handlerRenew(){
      let params = {
        ...this.quoteInformationForm
      }
      let {order_no} = this.form
      params.policy_type=201
      let path = {
        10:'/quotation/create/motor',
        11:'/quotation/create/contractors',
        12:'/quotation/create/travel',
        13:'/quotation/create/home',
        14:'/quotation/create/employee',
        15:'/quotation/create/business',
        16:'/quotation/create/domestic',
        17:'/quotation/create/construction',
        18:'/quotation/create/other',
      }
      this.setQuoteInformationForm(params)
      this.$router.push({
        path:path[params.product_id],
        query:{
          order_no
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.right {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  margin-top: 30px;
  .card {
    background: white;
    border-radius: 5px;
    padding: 15px;
  }
  .button-blue{
    width: 100%;
    margin-bottom: 20px;
    margin-left: 0;
  }
  .is-bold{
    font-weight: bold;
    font-size: 24px;
    color: #000000;
    margin-bottom: 20px;
  }
  .table{
    .tr{
      height: 49px;
      padding: 5px 10px;
    }
    .flex{
      flex: 1;
     justify-content: space-between;
      font-size: 14px;
      margin-left: 10px;
      .link{
        font-size: 14px;
      }
      .button-theme{
        padding: 0px 12px;
        border-radius: 4px;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 150%;
        color: #FFFFFF;
      }
    }
  }
}
.margin-right{
  margin-right: 5px;
}
</style>
