<template>
  <section>
    <div class="table mg-b-20">

      <div class="th">
        <span>New Policy Premium</span>
      </div>
    </div>
    <el-row :gutter="20">
      <v-row-col>
        <div>
          <div class="table">
            <div></div>
            <div
              class="tr">
              <span v-if="productId==10">Base Premium</span>
              <span v-else> Premium</span>
              <span>
                {{premium.base_premium|formatMoney(2,true)}}
              </span>
            </div>
            <div
              v-if="productId==10"
              class="tr">
              <span>Client Discount</span>
              <span>
                {{premium.client_discount}} %
              </span>
            </div>
            <div
              v-if="productId==10"
              class="tr">
              <span>Loading</span>
              <span>
                {{premium.loading}} %
              </span>
            </div>
            <div
              v-if="productId==10"
              class="tr">
              <span>NCD</span>
              <span>
                {{premium.ncd}} %
              </span>
            </div>
            <div
              v-if="productId==10"
              class="tr">
              <span>Basic Premium</span>
              <span>
                {{premium.basic_premium|formatMoney(2,true)}}
              </span>
            </div>
            <div
              v-if="productId==10"
              class="tr">
              <span>Additional Premium</span>
              <span>
                {{premium.additional_premium|formatMoney(2,true)}}
              </span>
            </div>
            <div
              class="tr">
              <span>LA Levy</span>
              <span>
                {{premium.ia_levy_vl|formatMoney(2,true)}}
              </span>
            </div>
            <div
              v-if="showEcPercent"
              class="tr">
              <span>EC Levy %</span>
              <span>
                {{premium.ec_levy}} %
              </span>
            </div>
            <div
              v-if="showEcHKD"
              class="tr">
              <span>EC Levy</span>
              <span>
                {{premium.ec_levy_vl|formatMoney(2,true)}}
              </span>
            </div>
            <div
              v-if="productId==10"
              class="tr">
              <span>MIB</span>
              <span>
                {{premium.mib_vl|formatMoney(2,true)}}
              </span>
            </div>
            <div
              class="tr">
              <span>Total Premium</span>
              <span>
                {{premium.total_premium|formatMoney(2,true)}}
              </span>
            </div>
          </div>
        </div>
      </v-row-col>
      <v-row-col>
        <div class="table">
          <div></div>
          <div
            class="tr">
            <span>Gross Premium</span>
            <span>
              {{premium.gross_premium|formatMoney(2,true)}}
            </span>
          </div>
          <div
            class="tr">
            <span>Amount Received</span>
            <span>
              {{premium.amount_received|formatMoney(2,true)}}

            </span>
          </div>
          <div
            class="tr">
            <span>Special Discount %</span>
            <span>
              {{premium.special_discount}} %
            </span>
          </div>
          <div
            class="tr">
            <span>Sub Net %</span>
            <span>
              {{premium.net}} %
            </span>
          </div>
          <div
            class="tr">
            <span>Sub Net</span>
            <span>
              {{premium.net_vl|formatMoney(2,true)}}
            </span>
          </div>
          <!--          <div-->
          <!--            class="tr">-->
          <!--            <span>Net Comm.$</span>-->
          <!--            <span>-->
          <!--              {{premium.net_commission_vl|formatMoney(2,true)}}-->
          <!--            </span>-->
          <!--          </div>-->

          <div
            class="tr">
            <span>Insurer Net %</span>
            <span>
              {{premium.insurer_net}} %
            </span>
          </div>
          <div
            class="tr">
            <span>Insurer Net</span>
            <span>
              {{premium.insurer_net_vl|formatMoney(2,true)}}
            </span>
          </div>
          <!--          <div-->
          <!--            class="tr">-->
          <!--            <span>Insurer Net Comm.$</span>-->
          <!--            <span>-->
          <!--              {{premium.insurer_net_commission_vl|formatMoney(2,true)}}-->
          <!--            </span>-->
          <!--          </div>-->
        </div>
      </v-row-col>
      <v-row-col>
        <div class="table mg-t-20">
          <div></div>
          <div
            class="tr">
            <span>Sub Comm.</span>
            <span>
              {{premium.referrer_commission_vl|formatMoney(2,true)}}
            </span>
          </div>
          <div
            class="tr">
            <span>RM Comm.</span>
            <span>
              {{premium.owner_commission_vl|formatMoney(2,true)}}
            </span>
          </div>
        </div>
      </v-row-col>
    </el-row>
    <transaction :form="form"></transaction>
  </section>
</template>

<script>
import MixinFormat from '@/mixins/format'
import MixinOption from '@/mixins/options'
import transaction from '@/views/components/info/transcation'
export default {
  name: 'premium',
  components:{
    transaction
  },
  mixins: [MixinFormat,MixinOption],
  props:{
    form:{
      type:Object,
      default:()=>({})
    }
  },
  computed:{
    premium(){
      let {premium={}} = this.form
      return premium
    },
    productId(){
      return this.form.product_id
    },
    showEcPercent(){
      return this.productId==13
    },
    showEcHKD(){
      return [13,14,15,16,17,18].includes(this.productId)
    }
  }
}
</script>

<style scoped lang="scss">
  .table{
    border: 1px solid #CBD5E0;
  }
</style>
