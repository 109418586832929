<template>
  <v-dialog
    v-model="dialog"
    class="width-dialog-sm-and-down dialog-box"
    width="430"
  >
    <div class="dialog-container width-dialog-sm-and-down">
      <div class="dialog-header">
        <h2 class="color-dark">Send {{sendType}} to Client</h2>
      </div>
      <div
        class="dialog-body"
      >
        <div
          class="transparent sent-content"
        >
          <el-form
            ref="form"
            :model="formData"
            :rules="rules"
          >
            <div >
              <div
                class="form-item">
                <el-form-item
                  ref="send_type"
                  :rules="rules.select"
                  prop="send_type">
                  <v-radio-group
                    v-model="formData.send_type"
                    :block="true"
                    :list="radioList"
                  />
                </el-form-item>

              </div>
            </div>
          </el-form>
        </div>

      </div>
      <div
        class="dialog-footer"
        align="right">
        <div class="btn-container">
          <v-button
            size="small"
            class="button-theme-block"
            @click="handleCancel">Cancel
          </v-button>
          <v-button
            size="small"
            type="primary"
            class="button-theme"
            @click="handlerSave">Confirm
          </v-button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import MixinOptions from '@/mixins/options'
import {sendAppointee} from '@/api/appointee'
import MixinRules from '@/mixins/rules'

export default {
  name: 'sendDialog',
  mixins: [MixinOptions,MixinRules],

  props:{

    form: {
      type: Object,
      default: () => ({})
    },
    orderNo:{
      type: [String,Number],
      default:'',//1// hire 2:issue
    },
    uploadType:{
      type: [String,Number],
      default:1,//1// hire 2:issue
    },
    value:{
      type: Boolean,
      default:false,//1// hire 2:issue
    },
  },
  data() {
    return {
      dialog:false,
      isSent:false,
      date:'',
      formData:{
        send_type:''
      }
    }
  },
  computed:{
    sendType(){
      let obj = {
        2:'Cover Note',
        1:'Policy',
        3:'Receipt',
        4:'Quotation'
      }
      return obj[this.uploadType]
    },
    radioList(){
      let {policy_holder_mobile,agent_mobile_no} = this.form||{}
      let arr_2 = `SMS policyholder “${policy_holder_mobile}”`
      if(agent_mobile_no)
        arr_2 = arr_2+` and authorised person “${agent_mobile_no}”`
      let arr_1 = `SMS client “${policy_holder_mobile}”`
      return [
        {
          name:'Printed hard copy',
          id:1
        },
        {
          name:this.uploadType==3?arr_1:arr_2,
          id:2
        }
      ]
    }
  },
  watch:{
    value:{
      immediate:true,
      handler(val){
        this.dialog = val
      }
    },
    form: {
      deep: true,
      immediate: true,
      handler(val) {
      }
    }
  },
  created() {

  },
  methods: {
    // file-container 組件刪除文件
    handlerSave(){
      this.$refs['form'].validate(valid => {
        if(valid) {
          this.network().sendAppointee()
          this.$refs.form.resetFields()
        } else {
        }
      })

    },
    handleCancel(){
      this.$emit('input',false)
    },
    network() {
      return {
        sendAppointee: async (params) => {
          params = {}
          params.type  = this.uploadType
          let { data } = await sendAppointee({...params,...this.formData,order_no:this.orderNo})
          this.$emit('input',false)
          this.$emit('refresh')
        },
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.dialog-box{
  width: 430px!important;
  ::v-deep .el-dialog{
    width: 430px;
  }
  .dialog-container {
    padding: 30px;
    color: #616266;
    width: 430px;
    .dialog-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      //margin: 0 -15px;
      //padding: 30px 30px 0 30px;
      //   border-bottom: 1px solid #dddfe7;
      h2 {
        font-size: 24px;
        color: $gray;
      }
    }
    .dialog-body{
      .sent-content{
        margin: 20px 0;
        color: #718096;
        .el-checkbox{
          margin-right: 10px;
        }
      }
      .form-item{
        margin: 0 0px 30px 0px;
        span{
          color: #718096;
        }
      }
    }

    .transparent{
      margin-bottom: 20px;
    }
  }
  .black-color{
    color: #2D3748!important;
  }
  /deep/.el-radio-group{
    .el-radio{
      margin-bottom: 10px;
    }
  }
}
</style>
