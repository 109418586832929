<template>
  <section class="page-container">
    <el-form
      ref="form"
      :model="formData"
    >
      <el-row
        :gutter="40"
        justify="space-around">
        <el-col
          :xs="24"
          :sm="24"
          :md="12"
          :lg="17"
          :xl="17"
          class="page-content-and-up"
        >
          <div
          >
            <div class=" flex flex-justify">
              <div
                class="header-title-back"
                @click="() => $router.back()">
                <i class="el-icon-arrow-left"></i>
                <span>
                  Back
                </span>
              </div>
              <div class="flex button-margin">
                <v-button
                  v-if="formData.can_delete"
                  class="button-delete"
                  @click="handlerDelete">
                  <font-awesome-icon
                    icon="trash"
                    class="margin-right"

                  ></font-awesome-icon>
                  <span>Delete</span></v-button>
                <!--                <v-button-->
                <!--                  class="button-theme"-->
                <!--                  @click="openFileDocument(formData.receipt_document)">-->
                <!--                  <font-awesome-icon-->
                <!--                    icon="print"-->
                <!--                    class="margin-right"></font-awesome-icon>-->
                <!--                  <span>Receipt</span>-->
                <!--                </v-button>-->
                <!--                <v-button-->
                <!--                  class="button-theme"-->
                <!--                  @click="openFileDocument(formData.invoice_document)">-->
                <!--                  <font-awesome-icon-->
                <!--                    icon="print"-->
                <!--                    class="margin-right"></font-awesome-icon>-->
                <!--                  <span>Invoice</span>-->
                <!--                </v-button>-->
                <!--                <v-button-->
                <!--                  class="button-theme"-->
                <!--                  @click="openFileDocument(formData.quotation_document)">-->
                <!--                  <font-awesome-icon-->
                <!--                    icon="print"-->
                <!--                    class="margin-right"></font-awesome-icon>-->
                <!--                  <span>Quote</span>-->
                <!--                </v-button>-->
              </div>
            </div>
            <div class="flex-justify flex img-flex">
              <div class="flex flex-algin">
                <div>
                  <span
                    v-if="showOtherInsurer"
                    class="company-logo">
                    <img
                      :src="getOptionData('insureOptions',formData.company_id).logo"
                      alt="company" />
                  </span>
                  <span
                    v-else
                    class="margin-right-ten">Other Insurer</span>
                </div>
                <div class="flex-cloumn">
                  <div><v-button
                    :class="getOptionName('buttonColorClassOption',formData.policy_status)"
                    disabled
                    plain>{{getOptionName('statusOptions',formData.policy_status)}}</v-button></div>
                  <h2
                    v-if="formData.product_id==10"
                    class="small-font-size">Motor Insurance - {{getOptionName('carClassOptions',formData.class_id)}}</h2>
                  <h2
                    v-else
                    class="small-font-size">{{getOptionName('productTypeOptions',formData.product_id)}}</h2>
                </div>

              </div>
              <div class="no-info">
                <div  class="flex">
                  <span >Created Date:</span>
                  <span>{{showHKTime(formData.created_date)}}</span>
                </div>
                <div
                  v-if="formData.product_id==10"
                  class="flex">
                  <span >Cover Note No.:</span>
                  <span>{{formData.covernote_no}}</span>
                </div>
                <div class="flex">
                  <span >Policy No.:</span>
                  <span>{{formData.policy_no}}</span>
                </div>
              </div>
            </div>
            <div class="flex-justify flex margin-bottom">
              <div>
                <v-button
                  disabled
                  :class="getOptionName('buttonClassOption',formData.policy_type)"
                >{{getOptionName('policyTypeOption',formData.policy_type)}} </v-button>
              </div>
              <div class="flex">
                <v-button
                  v-if="isPayOnAccount"
                  class="button-blue"
                  @click="handlerSettle(formData.policy_type)">Settle Payment</v-button>
                <v-button
                  class="button-theme"
                  @click="handlerEdit(formData.policy_type)">Edit</v-button>
              </div>
            </div>
            <!--            <div-->
            <!--              v-if="isHire"-->
            <!--              class="flex-justify flex margin-bottom block-box">-->
            <!--              <div class="flex">-->
            <!--                <span>Hire Purchase Owner ：</span>-->
            <!--                <span>{{getOptionName('globalOptions.hire_purchase',hirePurchaseOwner)}}</span>-->
            <!--              </div>-->
            <!--              <div class="flex">-->
            <!--                <span>Sent Date：{{showHkDate(sendDate)}}</span>-->
            <!--              </div>-->
            <!--              <div>-->
            <!--                <v-button-->
            <!--                  class="button-theme"-->
            <!--                  :disabled="isCancel"-->
            <!--                  @click="updateHirePurchase(1)">Update</v-button>-->
            <!--              </div>-->
            <!--            </div>-->
          </div>
          <div
            v-if="incomplete"
            class="premium-info--ul">
            <ul>
              <li v-if="incomplete.includes('covernote')">
                <span class="flex flex-center">
                  <span class="icon-error">!</span>
                  <span class="span-options orange">Incomplete</span>
                  <span>Please Upload Cover Note</span>
                </span>
                <span>
                  <v-button
                    class="button-theme"
                    @click="handleUploadCP(2)">Upload</v-button>
                </span>
              </li>
              <li v-if="incomplete.includes('send_covernote')">
                <span class="flex flex-center-center">
                  <span class="icon-error">!</span>
                  <span class="span-options orange">Incomplete</span>
                  <span>Send Cover Note to Client </span>
                </span>
                <span>
                  <v-button
                    class="button-theme"
                    @click="handleSend(2)">Send</v-button>
                </span>
              </li>
              <li  v-if="incomplete.includes('policy')">
                <span class="flex flex-center-center">
                  <span class="icon-error">!</span>
                  <span class="span-options orange">Incomplete</span>
                  <span>Please Upload Policy</span>
                </span>
                <span>
                  <v-button
                    class="button-theme"
                    @click="handleUploadCP(1)">Upload</v-button>
                </span>
              </li>
              <li  v-if="incomplete.includes('send_policy')">
                <span class="flex flex-center-center">
                  <span class="icon-error">!</span>
                  <span class="span-options orange">Incomplete</span>
                  <span>Send Policy to Client </span>
                </span>
                <span>
                  <v-button
                    class="button-theme"
                    @click="handleSend(1)">Send</v-button>
                </span>
              </li>
              <li v-if="incomplete.includes('full_documents_received')">
                <span class="flex flex-center-center">
                  <span class="icon-error">!</span>
                  <span class="span-options orange">Incomplete</span>
                  <span>Full Set Doc. Received?</span>
                </span>
                <span>
                  <v-button
                    class="button-theme"
                    @click="handleUploadCP(3)">Update</v-button>
                </span>
              </li>
            </ul>
          </div>

          <premium
            v-if="!isEndorsement&&!isCancel"
            :form="formData">
          </premium>
          <endorsement-premium
            v-if="isEndorsement"
            :form="formData"
            @refresh="updateCoverage"></endorsement-premium>
          <cancel-premium
            v-if="isCancel"
            :form="formData"
            @refresh="updateCoverage"></cancel-premium>
          <policy-document-info
            :form="formData"
            @upload="handleUpdateDoc"></policy-document-info>
          <document-info
            is-premium
            :form="formData"></document-info>
          <payment-info
            v-if="formData.payment"
            :form="formData.payment"></payment-info>
          <moto-info
            v-if="productId==10"
            is-premium
            :formData="formData"></moto-info>
          <constractors-info
            v-if="productId==11"
            :formData="formData"></constractors-info>
          <travel-info
            v-if="productId==12"
            :formData="formData"></travel-info>
          <home-info
            v-if="productId==13"
            :formData="formData"></home-info>
          <employees-info
            v-if="productId==14"
            :formData="formData">
          </employees-info>
          <business-info
            v-if="productId==15"
            :formData="formData"></business-info>
          <domestic-info
            v-if="productId==16"
            :formData="formData"></domestic-info>
          <construction-info
            v-if="productId==17"
            :formData="formData">
          </construction-info>
          <other-info
            v-if="productId==18"
            :formData="formData"></other-info>

          <div
            v-if="isAtm"
            align="right">
            <v-button
              class="button-theme"
              @click="handlerUpdateAtm">Update ATM</v-button>
          </div>
        </el-col>
        <el-col
          class="padding-none-sm-and-down  page-content-auto"
          :xs="24"
          :sm="24"
          :md="24"
          :lg="5"
          :xl="5">
          <div class="page-content-fixed">
            <policy-dailog
              :isRenew="formData.is_want_renew"
              :isPayOnAccount="isPayOnAccount"
              :policyname="getOptionName('policyTypeOption',formData.policy_type)"
              :isAble="isAble "></policy-dailog>

            <!--            <document-status-dialog-->
            <!--              v-if="!isCancel"-->
            <!--              :form="formData"-->
            <!--              @step="handlerStep"-->
            <!--            ></document-status-dialog>-->
          </div>
        </el-col>
      </el-row>
    </el-form>
    <policy-status-dialog
      ref="policyStatusDialog"
      :type="step"
      :order-no="orderNo"
      :form="formData"
      @updateCoverage="updateCoverage">
    </policy-status-dialog>
    <update-atm-dialog
      ref="updateAtm"
      :order-no="orderNo"
      :form="formData.payment"
      @updateCoverage="updateCoverage"></update-atm-dialog>
    <upload-cover-or-policy-dialog
      ref="upoadCoverOrPolicy"
      v-model="showCPDialog"
      :order-no="orderNo"
      :form="formData"
      :upload-type="uploadType"
      @refresh="updateCoverage"
    ></upload-cover-or-policy-dialog>
    <send-dialog
      ref="sendDialog"
      v-model="showSendDialog"
      :order-no="orderNo"
      :form="formData"
      :upload-type="sendType"
      @refresh="updateCoverage"
    ></send-dialog>
  </section>
</template>

<script>
import premium from '@/views/components/info/premium'
import endorsementPremium from '@/views/components/info/endrosmentPremium'

import policyDailog from '@/views/components/dialog/policyDailog'
import documentStatusDialog from '@/views/components/dialog/documentStatusDialog'
import policyStatusDialog from '@/views/components/dialog/policyStatusDialog'
import policyReceived from '@/views/components/dialog/policyReceived'
import cancelPremium from '@/views/components/info/cancelPremium'
import {mapActions, mapState} from 'vuex'
import {deleteOrder, getOrderDetail} from '@api/order'
import MixinOptions from '@/mixins/options'
import paymentInfo from '@/views/components/info/paymentInfo'
import motoInfo from '@/views/quotation/motor/motoInfo'
import constractorsInfo from '@/views/quotation/contractors/constractorsInfo'
import travelInfo from '@/views/quotation/travel/travelInfo'
import homeInfo from '@/views/quotation/home/homeInfo'
import employeesInfo from '@/views/quotation/employees/employeesInfo'
import BusinessInfo from '@/views/quotation/business/businessInfo'
import domesticInfo from '@/views/quotation/domestic/domesticInfo'
import ConstructionInfo from '@/views/quotation/construction/constructionInfo'
import OtherInfo from '@/views/quotation/other/otherInfo'
import updateAtmDialog from '@/views/components/dialog/updateAtmdialog'
import uploadCoverOrPolicyDialog from '@/views/components/dialog/uploadCoverOrPolicyDialog'
import sendDialog from '@/views/components/dialog/sendDialog'
import documentInfo from '@/views/components/info/documentInfo'
import policyDocumentInfo from '@/views/components/info/policyDocumentInfo'

export default {
  name: 'premiumInfo',
  components: {
    OtherInfo,
    domesticInfo,
    ConstructionInfo,
    BusinessInfo,
    premium,
    policyDailog,
    motoInfo,
    endorsementPremium,
    paymentInfo,
    policyStatusDialog,
    cancelPremium,
    constractorsInfo,
    travelInfo,
    homeInfo,
    employeesInfo,
    // documentStatusDialog,
    updateAtmDialog,
    documentInfo,
    uploadCoverOrPolicyDialog,
    sendDialog,
    policyDocumentInfo
  },
  mixins: [MixinOptions],
  props:{
    form:{
      type:Object,
      default:()=>({})
    },
    isBack:{
      type:Boolean,
      default:true
    }
  },
  data(){
    return{
      uploadType:'',
      showCPDialog:false,
      sendType:'',
      showSendDialog:false,
      type:1,
      step:0,
      formData:{
        payment:{},
      },
      subForm: {
        'set-payment':false,
      },
    }
  },
  computed: {
    ...mapState('quotation', ['quoteInformationForm']),
    incomplete(){
      let {incomplete=[]}  = this.formData||{}
      return incomplete||[]
    },
    productId(){
      return  this.formData.product_id
    },
    orderNo(){
      return this.$route.query.order_no
    },
    infoType(){
      return  this.formData.policy_type==203
    },
    isEndorsement(){
      return  this.formData.policy_type==203
    },
    isCancel(){
      return  this.formData.policy_type===204
    },
    hirePurchaseOwner(){
      let {coverage} =this.formData
      if(coverage)
      {
        let {hire_purchase_owner} = coverage
        return hire_purchase_owner
      }else
        return 0
    },
    sendDate(){
      let {coverage} =this.formData
      if(coverage)
      {
        let {policy_send_to_hire_purchase_owner_date} = coverage
        return policy_send_to_hire_purchase_owner_date
      }else
        return ''
    },
    isHire(){
      let {coverage={}} =this.formData
      let {hire_purchase_owner} = coverage
      return hire_purchase_owner===1
    },
    isAble(){
      return this.formData.is_in_force
    },
    showOtherInsurer(){
      let arr =  [1,4,11,3]
      return arr.includes(this.formData.company_id)
    },
    isAtm(){
      let {payment={}} = this.formData||{}
      return payment&&payment.type==4
    },
    isPayOnAccount(){
      let {payment={}} = this.formData||{}
      return payment&&payment.type==6
    }
  },
  created() {
    this.network().getOrderDetail()
  },
  methods:{
    ...mapActions('quotation', ['setQuoteInformationForm','setProductID']),
    handleUploadCP(type){
      this.uploadType  = type
      this.showCPDialog = true
    },
    handleSend(type){
      this.sendType  = type
      this.showSendDialog = true
    },
    handleUpdateDoc(type,isUpload){
      if(isUpload)
        this.handleSend(type)
      else this.handleUploadCP(type)
    },
    handlerSettle(){
      this.$router.push({
        path:'/update/payment',
        query:{
          order_no:this.orderNo
        }
      })
    },
    handlerUpdateAtm(){
      this.$refs.updateAtm.showDialog()
    },
    handlerStep(step){
      this.step = step
      if(step==0){
        this.handlerEdit(this.formData.policy_type,true)
      }else
        this.$refs.policyStatusDialog.showReferDialog()
    },
    updateHirePurchase(type){
      this.type = type
      this.$refs.hirePurchaseDialog.showReferDialog()
    },
    updateData(data){

    },
    updateCoverage(){
      this.uploadType = ''
      this.sendType = ''
      this.network().getOrderDetail()
    },
    handlerEdit(type,isDoucment){
      let order_no = this.$route.query.order_no
      let params = {
        is_edit:1,
        is_save:true,
        ...this.quoteInformationForm
      }
      this.setQuoteInformationForm(params)
      if(type===203){
        this.$router.push({
          path:'/quotation/endorsement',
          query:{
            isDoucment
          }
        })
      }
      else if(type===204)
        this.$router.push('/quotation/cancel')
      else{
        let path = {
          10:'/quotation/create/motor',
          11:'/quotation/create/contractors',
          12:'/quotation/create/travel',
          13:'/quotation/create/home',
          14:'/quotation/create/employee',
          15:'/quotation/create/business',
          16:'/quotation/create/domestic',
          17:'/quotation/create/construction',
          18:'/quotation/create/other',
        }
        this.$router.push({
          path:path[this.productId],
          query:{
            isDoucment,
            order_no
          }
        })
      }
    },
    openFileDocument(data){
      const { url } = data
      if(url) {
        window.open(url, '_blank')
      }
    },
    handlerDelete(){
      this.$ConfirmBox({
        title: '',
        message: 'Are you sure you want to delete this policy?',
        confirmBtn:'Yes',
        cancelBtn:'No'
      }).then(() => {
        this.network().deleteOrder()
      })
    },
    network() {
      return {
        getOrderDetail: async () => {
          let order_no = this.$route.query.order_no
          let { data } = await getOrderDetail({order_no:order_no})
          this.formData = data.data
          let params = {
            ...this.formData,
            order_no,
          }

          this.setQuoteInformationForm(params)
          this.setProductID(this.formData.product_id)
        },
        deleteOrder: async () => {
          let params ={
            order_no:this.orderNo
          }
          let { data } = await deleteOrder(params)
          let {policy_id} =data||{}
          if(policy_id>0)
          {
            this.$router.replace({
              path:'/policy/info',
              query:{
                policy_id
              }
            })
          }else
            this.$router.replace('/policy/list')
          // this.setQuoteInformationForm(null)
        },
      }
    }
  }
}
</script>

<style scoped lang="scss">
.page-container{
  .header-title{
    padding: 10px 0;
  }
  ::v-deep .table{
    .th{
      background: #CBD5E0;
      box-shadow: 0px 1px 0px #CBD5E0;
      font-weight: bold;
      font-size: 14px;
    }
  }
  .img-flex{
    margin: 20px 0;
    .no-info{
      background: #EDF2F7;
      border: 1px solid #CBD5E0;
      box-sizing: border-box;
      border-radius: 8px;
      padding: 10px;
      span:nth-child(1){
        flex: 1;
        font-size: 14px;
        color: #2D3748;
        font-weight: bold;
        width: 120px;
      }
      span:nth-child(2){
        flex: 1;
        font-size: 14px;
        text-align: right;
        color: #2D3748;
        width: 200px;
      }
    }
  }
  .flex-cloumn{
    display: flex;
    flex-direction: column;
    span{
      font-weight: bold;
      font-size: 24px;
    }
  }
  .margin-bottom{
    margin-bottom: 20px;
  }
  .flex{
    flex-wrap: wrap;
  }
.block-box{
  background: #EDF2F7;
  border-radius: 8px;
  padding: 20px;
  .flex{
    span:nth-child(1){
      font-weight: bold;
      font-size: 14px;
      color: #718096;
    }
    span:nth-child(2){
      font-size: 14px;
      color: #718096;
    }
  }
}
  .flex-algin{
    align-items: center;
  }
  .company-logo{
    width: 100px;
    height: 100px;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
      display: block;
      width: 100%;
    }
  }
  .button-theme{
    .margin-right{
      margin-right: 6px;
    }
  }
  .button-delete{
    .margin-right{
      margin-right: 6px;
    }
  }
  .header-title-back{
    margin-bottom: 6px;
  }
  .button-margin{
    .el-button{
      margin-left: 0!important;
      margin-right: 10px;
      margin-bottom: 6px;
    }
  }
  .premium-info--ul{
    margin-bottom: 20px;
    ul{
      li{
        padding: 10px 20px;
        background: #FFFFFF;
        border: 1px solid #CBD5E0;
        margin: -1px 0px;
        font-weight: 700;
        font-size: 14px;
        line-height: 150%;
        display: flex;
        justify-content: space-between;
        color: #2D3748;
      }
    }

    .span-options{
      padding: 2px 8px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      background: #fff;
      border-radius: 4px;
      margin-left: 15px;
      margin-right: 15px;
      &.orange{
        color: #ED8936;
        border: 1px solid #ED8936;
      }
    }
  }
}
</style>
